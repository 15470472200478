import './Instruction.css';
import { useValues } from '../contexts/AppContext';

const Instruction = ({ instruction }) => {
    const { instructions, setInstructions, setCenter } = useValues();
    const handleClick = () => {
        setInstructions(instructions.map(currentValue => {
            if (currentValue.id === instruction.id) {
                return {
                    ...currentValue,
                    showShape: !currentValue.showShape
                }
            }
            return {
                ...currentValue,
                showShape: false
            }
        }))
        setCenter(instruction.shape[0]);
    }
    return (
        <div
            onClick={handleClick}
            className='d-flex align-items-center border px-2 instruction text-white'
            style={{
                minHeight: '80px',
                borderRadius: '10px',
                background: instruction.showShape ? 'rgba(5, 60, 117, 255)' : 'rgb(14, 163, 237)'
            }}
        >
            {instruction.text}
        </div>
    );
}

export default Instruction;