import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className='alert alert-danger d-flex flex-column align-items-center' style={{fontSize: "large"}}>
                <span>This page does not exits</span>
                <span> 404</span>
            </div>
            <p>Return to <Link to='/'>Home</Link></p>
        </div>
    )
}

export default NotFound;