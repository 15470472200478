import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import urlServer from '../utils/urlServer';
import { useNavigate } from 'react-router-dom';
import { useValues } from '../contexts/AppContext';
import userSession from '../utils/userSession';
import Layout from './Layouts/Layout';
import LoadingButton from '../components/LoadingButton';

const Login = () => {
    const url = urlServer('login')
    const { setIsAuthenticated } = useValues();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPWD, setShowPWD] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [{ loading }, refresh] = useAxios(url, { manual: true });
    const navigateTo = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e);
        refresh({
            method: "post",
            data: {
                email,
                password
            }
        }).then(result => {
            const { data } = result;
            setIsAuthenticated(userSession(data.nv_user));
            navigateTo('/');

        }).catch(err => {
            console.log(err);
            const { data } = err.response;
            setErrorMessage(data.message);
        })
    }
    return (
        <Layout>
            <div className='d-flex justify-content-center align-items-center'
                style={{
                    height: '100vh',
                }}
            >
                <form
                    className='d-flex flex-column justify-content-around align-items-center border py-3 gap-4'
                    style={{
                        width: '20vw',
                        borderRadius: '10px'
                    }}
                    onSubmit={handleSubmit}
                >
                    <h3>
                        Login
                    </h3>
                    {
                        errorMessage.trim() !== '' &&
                        <p className='alert alert-danger'>
                            {errorMessage}
                        </p>
                    }
                    <div className='form-group'>
                        <input className='form-control' type='email' placeholder='Email' required onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className='form-group'>
                        <input className='form-control' type={showPWD ? 'text' : 'password'} placeholder='Password' required onChange={e => setPassword(e.target.value)} />
                        <div>
                            <input type='checkbox' id='checkbox' onChange={() => { setShowPWD(!showPWD) }} />
                            <label htmlFor='checkbox' className='mx-2'>Show Password</label>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <LoadingButton loading={loading} text='Login'/>
                    </div>
                </form>
            </div>
        </Layout>
    );
}

export default Login;