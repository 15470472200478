import L from 'leaflet';

export const originIcon = L.icon({
    iconUrl: require('../assets/origine.png'),
    iconSize: [48, 48],
    iconAnchor: [22, 48]
});

export const destinationIcon = L.icon({
    iconUrl: require('../assets/dest.png'),
    iconSize: [48, 48],
    iconAnchor: [22, 48]
});