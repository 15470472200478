import React, { createContext, useContext } from "react";

const AppContext = createContext();

export const useValues = () => {
    return useContext(AppContext);
}

const AppProvider = ({ value, children }) => {
    return (
        <AppContext.Provider
            value={value}
        >
            {children}
        </AppContext.Provider>
    );
}
export default AppProvider;