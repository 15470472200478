import React from 'react';
import Map from '../components/Map';
import Layout from './Layouts/Layout';

const Home = () => {
    return (
        <Layout>
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    position: 'relative',
                }}
            >
                <Map />
            </div>
        </Layout>
    );
}

export default Home;