import React, { useEffect } from 'react';
import { Circle, FeatureGroup, Marker, Polyline, Popup, useMapEvents } from 'react-leaflet';
import { useValues } from '../contexts/AppContext';
import { destinationIcon, originIcon } from './IconPosition';
import { countComa } from '../utils/utils';

const DrawShape = () => {
    const { center, shape, instructions, setInstructions, setCenter, origin, destination } = useValues();
    const map = useMapEvents({});
    const handleClick = (instruction) => {
        setInstructions(instructions.map(currentValue => {
            if (currentValue.id === instruction.id) {
                return {
                    ...currentValue,
                    showShape: !currentValue.showShape
                }
            }
            return {
                ...currentValue,
                showShape: false
            }
        }))
        setCenter(instruction.shape[0]);
    }
    useEffect(() => {
        map.flyTo(center, map.getZoom());
    }, [shape, center, map]);
    return (
        <>
            <FeatureGroup pathOptions={{ weight: 6, color: '#7500FF' }}>
                {
                    (countComa(origin) === 1) &&
                    <Marker position={origin.split(',')} icon={originIcon}>
                        <Popup>Origin</Popup>
                    </Marker>
                }

                {
                    instructions?.length > 0 &&
                    instructions.map(instruction => (
                        <Polyline
                            key={instruction.id}
                            positions={instruction.shape}
                            eventHandlers={{
                                click: () => handleClick(instruction)
                            }}
                        >
                            <Popup>{instruction.text}</Popup>
                        </Polyline>
                    ))
                }
                {
                    (countComa(destination) === 1) &&
                    <Marker position={destination.split(',')} icon={destinationIcon}>
                        <Popup>Destination</Popup>
                    </Marker>
                }

            </FeatureGroup>
            {
                instructions?.length > 0 &&
                instructions
                    .filter(instruction => instruction.showShape)
                    .map(instruction =>
                        <FeatureGroup pathOptions={{ weight: 8, color: 'red' }} key={instruction.id}>
                            <Polyline positions={instruction.shape}>
                                <Popup>
                                    {instruction.text}
                                </Popup>
                            </Polyline>
                            <Circle
                                center={instruction.shape[0]}
                                fillColor="blue"
                                radius={7}
                            />
                        </FeatureGroup>
                    )
            }
        </>
    );
}

export default DrawShape;