import Offcanvas from 'react-bootstrap/Offcanvas';
import { useValues } from '../../contexts/AppContext';
import Instruction from '../../components/Instruction';

function InstructionsBar() {
    const { showInstructionsBar, setShowInstructionsBar, instructions } = useValues();
    const handleClose = () => setShowInstructionsBar(false);
    return (
        <Offcanvas scroll={true} backdrop={false} show={showInstructionsBar} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Route instructions</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {
                    instructions?.length > 0 ?
                        <div
                            className='d-flex flex-column gap-2'
                        >
                            {
                                instructions.map(instruction => (
                                    <Instruction key={instruction.id} instruction={instruction} />
                                ))
                            }
                        </div> :
                        <h5 className='text-danger'>There are no instructions yet.</h5>
                }
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default InstructionsBar;