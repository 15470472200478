import React from 'react';
import Navbar from './Navbar';
import InstructionsBar from './InstructionsBar';
import { useValues } from '../../contexts/AppContext';
import FormMoreOptions from './FormMoreOptions';

const Layout = ({ children }) => {
    const { isAuthenticated } = useValues();
    return (
        <div className='d-flex flex-column' style={{ width: '100vw', height: '100vh' }}>
            <div>
                <Navbar />
            </div>
            <div className={`d-flex` + isAuthenticated && 'justify-content-center align-items-center'} style={{ width: '100%' }}>
                <div>
                    {children}
                </div>
                {
                    isAuthenticated &&
                    <>
                        <InstructionsBar />
                        <FormMoreOptions />
                    </>

                }
            </div>
        </div>
    );
}

export default Layout;