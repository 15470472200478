import { useEffect, useState } from 'react';
import { useValues } from '../contexts/AppContext';
import urlServer from '../utils/urlServer';
import useAxios from 'axios-hooks';
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap';
import { countComa } from '../utils/utils';

const RouteForm = () => {
    const { setInstructions, setShape, setCenter, origin, setOrigin, destination, setDestination, apiName, setApiName, originSide, destinationSide, responseFormat, shiftedPolyline, language } = useValues();
    const [checked, setChecked] = useState(false);
    const route = urlServer('get-route');
    const [{ loading }, refresh] = useAxios(route, { manual: true });
    const getRoute = () => {
        refresh({
            method: 'post',
            data: {
                origin,
                destination,
                apiName,
                origin_side: originSide,
                destination_side: destinationSide,
                shifted_polyline: shiftedPolyline,
                response_format: responseFormat,
                language
            }
        }).then(result => {
            console.log(result.data.shape);
            setShape(result.data.shape);
            setCenter(result.data.shape[0]);
            setInstructions(result.data.instructions);
        }).catch(err => {
            console.log(err);
            const { messages_error } = err.response.data;
            messages_error.map(element => alert(element));
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        getRoute();
    }
    useEffect(() => {
        if (checked && countComa(origin) === 1 && countComa(destination) === 1) {
            getRoute();
        }
        // eslint-disable-next-line
    }, [checked, origin, destination]);

    return (
        <Container fluid>
            <Form className='d-flex flex-column align-items-center my-2 gap-1' onSubmit={handleSubmit}>
                <Row className="d-flex justify-content-center align-items-center gap-2">
                    <FloatingLabel as={Col} lg={2} md={2} controlId='origin' label="Origin coordinates" className="px-1" style={{width: "calc(100%/3 - 100%/100)"}}>
                        <Form.Control type='text' placeholder='Origin coordinates' value={origin} onChange={e => setOrigin(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel as={Col} lg={2} md={2} controlId='destination' label="Destination coordinates" className="px-1" style={{width: "calc(100%/3 - 100%/100)"}}>
                        <Form.Control type='text' placeholder='Destination coordinates' value={destination} onChange={e => setDestination(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel as={Col} lg={2} md={2} controlId='select-api' label="Select an API" className="px-1" style={{width: "calc(100%/3 - 100%/100)"}}>
                        <Form.Select value={apiName} onChange={e => setApiName(e.target.value)}>
                            <option value='nv_route'>NV Route</option>
                            <option value='here'>Here</option>
                            <option value='nv_routing_engine'>NV Routing Engine</option>
                        </Form.Select>
                    </FloatingLabel>
                </Row>
                <Row className="d-flex justify-content-center gap-5 align-items-center">
                    <Form.Group as={Col} lg={2} md={2} style={{minWidth: "fit-content"}}>
                        <Form.Check
                            inline
                            type='switch'
                            id='check-validate'
                            label="Auto validate"
                            className='text-white'
                            onClick={e => setChecked(e.target.checked)}
                        />
                    </Form.Group>
                    <Form.Group as={Col} lg={1} md={1} style={{minWidth: "fit-content"}}>
                        <Button
                            type='submit'
                            disabled={loading || checked}
                            variant='success'
                            size="sm"
                        >
                            {
                                loading ?
                                    <Spinner size='sm' animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span >
                                    </Spinner > :
                                    <span>Validate</span>
                            }
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
        </Container >
    )
}

export default RouteForm;