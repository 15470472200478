import React from 'react';

const LoadingButton = ({ loading, text }) => {
    return (
        <button className='btn btn-primary'>
            {
                loading ?
                    <div className="spinner-border text-white" role="status">
                        <span className="sr-only"></span>
                    </div>
                    :
                    text
            }
        </button>
    );
}

export default LoadingButton;